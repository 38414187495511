import RefComponentComponent from '@wix/thunderbolt-elements/src/components/RefComponent/viewer/RefComponent';


const RefComponent = {
  component: RefComponentComponent
};


export const components = {
  ['RefComponent']: RefComponent
};


// temporary export
export const version = "1.0.0"
